const Player = (props) => {
  let channelObj= props.channel;
  let className=props.className
  
  let url=""
  if(channelObj!==null){
  if(channelObj.url && channelObj.url.startsWith("http"))
  url=channelObj.url
  else
  url=`https://www.youtube.com/embed/${channelObj.url}?autoplay=1`
    return (
    <div className={className}>
    <iframe class="iframe-player" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>);
  }
  else{
    return (
      <div className={className}>
      <iframe class="iframe-player" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>);
    }
  }

  export default Player;