import {initializeApp} from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBM9S3hC0zz9R9mYoI3Dw308oD2EUeukcI",
    authDomain: "kumanda-af990.firebaseapp.com",
    databaseURL: "https://kumanda-af990-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "kumanda-af990",
    storageBucket: "kumanda-af990.appspot.com",
    messagingSenderId: "201405314355",
    appId: "1:201405314355:web:259aff8d445f8d4ddc3a46",
    measurementId: "G-4MF6NZHWBN"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const fbDb = getDatabase(firebaseApp);

export default fbDb;
