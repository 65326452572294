import React from 'react'
import { useEffect, useState } from "react";
import ETable from './ETable';
import { Styles } from './dtableStyle';
import fbDb from './firebase';
import {initializeApp} from 'firebase/app';
import { getDatabase, ref, child, get, update,push,onValue} from 'firebase/database';
// Create an editable cell renderer



function TableChannel() {
  const fbDatabase = fbDb
  const columns = React.useMemo(
    () => [
      {
        Header: 'Channel',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'URL',
            accessor: 'url',
          },
        ],
      }
    ],
    []
  )

  const [channelsData, setChannelsData] = React.useState([])
  const [originalData] = React.useState(channelsData)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [newName, setnewName] = useState('')
  const [newUrl, setnewUrl] = useState('')

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setChannelsData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }



  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    const dbRef = ref(fbDatabase);
    get(child(dbRef, 'Channels')).then((snapshot) => {
  const channelsList=snapshot.val();
  if(channelsList){
   const channelsArrayData=[]
    Object.entries(channelsList).map(c=>channelsArrayData.push(c[1]))
  console.log(channelsArrayData)
  setChannelsData(channelsArrayData)
  }
});
    setSkipPageReset(false)
  }, [])

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => setChannelsData(originalData)
  const updateData = () => {
    const updates = {};
    updates['Channels'] = channelsData;
    update(ref(fbDatabase), updates);
  }
  const addData = () => {
    push(child(ref(fbDatabase), 'Channels'),{name:newName, url:newUrl});
  }

  return (
    <Styles>
      <button onClick={updateData}>Update Data</button>
      <button onClick={resetData}>Reset Data</button>
      <input type="text" onChange={(evt)=>setnewName(evt.target.value)} placeholder="name" />
      <input type="text" onChange={(evt)=>setnewUrl(evt.target.value)} placeholder="URL" />
      <button onClick={addData}>Ekle</button>
      <ETable
        columns={columns}
        data={channelsData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </Styles>
  )
}

export default TableChannel

