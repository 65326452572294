export function FullList({channels,open,pickChannel,onBlur}){
    if(open)
    return (
        <div onBlur={onBlur} className="channelsFull">
            {channels.map((c) => (
                <div className="channel">
                    <button
                        onClick={(e) => {
                            pickChannel(c);
                        }}
                    >
                        {c.name}
                    </button>
                </div>
            ))}
        </div>
    )
    else
    return <div></div>
} 