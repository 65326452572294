import { useEffect, useState } from "react";
import * as fb from './firebase'
import { Outlet, Link,BrowserRouter,Routes,Route } from "react-router-dom";
import React from "react";

const FormChannel = ({channel}) => {
  const [name, setName]= useState('')
  useEffect(()=>{
    console.log(channel)
  },[])

  const nameEdit=(n)=>{
    channel.name=n
  }
    return (
        <form>
          <input name="name" key={channel.name} type="text" onChange={e=>nameEdit(e.target.value)} value={channel.name} />
          <input type="text" value={channel.url} />
          <input type="checkbox" name="channels" checked={channel.blank} />
        </form>
      );
  };
export default FormChannel;
