import { useEffect, useState } from "react";
import useKeypress from "react-use-keypress";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import logo from "./logo.svg";
import qrcodeIcon from "./qr-code-scan.png";
import twittericon from "./tweet.svg";
import fbDb from "./firebase";
import styles from "./App.css";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  push,
  onValue,
  onChildChanged,
  update,
} from "firebase/database";
import {
  Outlet,
  Link,
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Player from "./Player";
import { FullList } from "./FullList";
function Home() {
  const params= useParams();
  
  const fbDatabase = fbDb;
  const loc = window.location;
  const [historyChannels, setHistoryChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [channels, setChannels] = useState([]);
  const [currentChannelIndex, setCurrentChannelIndex] = useState(-1);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentChannelName, setCurrentChannelName] = useState("kumanda");
  const [channelKey, setChannelKey] = useState("");
  const [pickedUrl, setPickedUrl] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [remoteMode, setRemoteMode] = useState(false);
  const [sideBarClass, setSideBarClass] = useState("sideBar");
  const [playerClass, setPlayerclass] = useState("playerFrame");
  const [backspanClass, setBackspanClass] = useState("");
  const [sidebarButtonText, setSidebarButtonText] = useState("<<");
  const [fullListOpen, setfullListOpen] = useState(false);

  useKeypress(["ArrowLeft", "ArrowRight", "Enter"], (event) => {
    if (event.key === "ArrowLeft") {
      zapChannel(-1);
    } else if (event.key === "Enter") {
      pickChannel(filteredChannels[0]);
    } else if (event.key === "ArrowRight") {
      zapChannel(1);
    }
  });
  console.log("kumandaid",params.id);
  useEffect(() => {console.log("1");
    
    //var pathName = loc.pathname.substring(loc.pathname.indexOf("/") + 1);
    if (params.id) {
      setChannelKey(params.id);
      setRemoteMode(true);
      setQrUrl(params.id);
      const pickChannelRef = ref(fbDatabase, "Userch/" + params.id);
      let chnObj={channel:0};
      set(pickChannelRef, chnObj);
    }

    const dbRef = ref(fbDatabase);
    get(child(dbRef, "Channels"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const channelsSnapshotData = snapshot.val();
          const channelsArrayData = [];
          if (channelsSnapshotData) {
            Object.entries(channelsSnapshotData).map((c) =>{

              channelsArrayData.push(c[1])
            }
            );
            setChannels(channelsArrayData);
          }
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    /*
    var postListRef = fb.database.ref('Channels');
    postListRef.once('value').then((snapshot) => {
      const channelsSnapshotData=snapshot.val()
      const channelsArrayData=[]
      if(channelsSnapshotData){
        Object.entries(channelsSnapshotData).map(c=>channelsArrayData.push(c[1]))
        setChannels(channelsArrayData)
      }
     
    });
*/
    let historyFromLocalstorage = localStorage.getItem("historyChannels");
    if (historyFromLocalstorage) {
      setHistoryChannels(JSON.parse(historyFromLocalstorage));
      setFilteredChannels(JSON.parse(historyFromLocalstorage));
    }
  }, [channelKey]);

  useEffect(() => {
    console.log("2");
    let newUserch = {
      channel: {
        id: 0,
        name: "",
        url: "",
      },
    };

    if (!channelKey) {
      //let pushedCh=userch.push(newUserch)
      const pushedCh = push(child(ref(fbDatabase), "Userch"));

      console.log(pushedCh.key);
      setChannelKey(pushedCh.key);
      setQrUrl(
        "http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=https%3A%2F%2Fkumanda.app%2F" +
          pushedCh.key +
          "&qzone=1&margin=0&size=200x200&ecc=L"
      );
      setPickedUrl("http://localhost:3000/" + pushedCh.key);
    }
  }, [channelKey]);

  useEffect(() => {
    console.log("3");
    console.log("chkey");
    console.log(channelKey);
    if (channelKey) {
      var userchKey = child(
        ref(fbDatabase),
        "Userch/" + channelKey
      );
      onChildChanged(userchKey, (snapshot) => {
        let userchVal = snapshot.val();
        console.log(userchVal);
        setCurrentChannel(snapshot.val());
      });
    }
  }, [channelKey]);

  const zapChannel = (next) => {
    let index = currentChannelIndex;
    if (index === -1) {
      if (next > 0) index = 0;
      else index = channels.length - 1;
    } else {
      if (next > 0) ++index;
      else --index;
    }

    if (index === channels.length) index = 0;
    pickChannel(channels[index]);
    setCurrentChannelIndex(index);
  };

  const pickChannel = (c) => {
    setCurrentChannelName(c.name);
    let channelsHistory = historyChannels;
    console.log("pick");
    console.log(c);
    const pickChannelRef = ref(fbDatabase, "Userch/" + channelKey);
    let chnObj={channel:c};
    set(pickChannelRef, chnObj);

    if (channelsHistory.findIndex((i) => i.name === c.name) === -1) {
      channelsHistory.push(c);
      setHistoryChannels(channelsHistory);
      localStorage.setItem("historyChannels", JSON.stringify(historyChannels));
    }
    if (c.blank) {
      window.open(c.url, "_blank");
      return false;
    } else setCurrentChannel(c);
  };

  const toggleSidebar = () => {
    if (sideBarClass === "sideBar") {
      setSideBarClass("sideBarMinimized");
      setPlayerclass("playerFrameMax");
      setBackspanClass("backr");
      setSidebarButtonText(">>");
    } else {
      setSideBarClass("sideBar");
      setPlayerclass("playerFrame");
      setBackspanClass("");
      setSidebarButtonText("<<");
    }
  };

  const filterChannels = (query, channels) => {
    let fchannels = channels.filter(function (el) {
      return query && el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    let channelsHistory = historyChannels;
    if (
      fchannels.length === 1 &&
      channelsHistory &&
      channelsHistory.findIndex((i) => i.name === fchannels[0].name) === -1
    ) {
      channelsHistory.push(fchannels[0]);

      setHistoryChannels(channelsHistory);
      localStorage.setItem("historyChannels", JSON.stringify(channelsHistory));
    }

    console.log("fchannels");
    console.log(historyChannels);
    if (fchannels.length === 0) {
      console.log("fch 0");
      return historyChannels;
    }
    return fchannels;
  };

  //console.log(channels)
  let showChannelPlayer = <span></span>;
  if (!remoteMode) {
    showChannelPlayer = (
      <Player className={playerClass} channel={currentChannel} />
    );
  }
  return (
    <div className="main">
      <div className={sideBarClass}>
        <h2
          style={{
            color: currentChannelName === "kumanda" ? "#fdfdfd" : "#404040",
          }}
        >
          {currentChannelName}
        </h2>
        <div className="channel-input">
          <input
            onClick={(evt) => {(evt.target.value = "");setfullListOpen(true)}}
            onChange={(evt) =>{
              setFilteredChannels(filterChannels(evt.target.value, channels));
              setfullListOpen(false);
            }
            }
            placeholder="kanal adı yazın"
            type="text"
          />
        </div>
        <div className="zapping-buttons">
          <button onClick={() => zapChannel(-1)} className="zapping">
            {"<"}
          </button>
          <button onClick={() => zapChannel(1)} className="zapping">
            {">"}
          </button>
          <button className="sidebarToggler" onClick={() => toggleSidebar()}>
            {sidebarButtonText}
          </button>
          <div>
          <a title="Twit at" target="_blank"
            class="twitter-share-button"
            href={
              "https://twitter.com/intent/tweet?url=kumanda.app&text=%23" +
              currentChannelName 
            }
          >
            <img src={twittericon} />
          </a>
          </div>
        </div>

        <div
          style={{ display: filteredChannels.length > 0 ? "block" : "none" }}
          className="channels"
        >
          {filteredChannels.map((c) => (
            <div className="channel">
              <button
                onClick={(e) => {
                  pickChannel(c);
                }}
              >
                {c.name}
              </button>
            </div>
          ))}
        </div>
        <br />
        <div
          style={{
            padding: "8px",
            paddingTop: "4px",
            clear: "both",
            marginTop: "10px",
            display: remoteMode ? "none" : "block",
          }}
        >
          <img className="qrcodeImg" src={qrUrl} alt="qr code" />

          <div className="qrinfoBox">
            <img style={{ width: "60px" }} src={qrcodeIcon} />
            <em>
              <strong>QR Kumanda</strong>
            </em>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ display: remoteMode ? "none" : "block" }}
      >
        <div className={backspanClass}>&nbsp;</div>
        {showChannelPlayer}
        
      </div>
      <FullList channels={channels} open={fullListOpen} pickChannel={pickChannel} onBlur={(evt) => {setfullListOpen(false)}} />
    </div>
  );
}

export default Home;
