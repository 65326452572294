import './App.css';
import { Outlet, Link,BrowserRouter,Routes,Route } from "react-router-dom";
import Home from './Home';
import TableChannel from './TableChannel';
import Channels from './Channels';
import Player from './Player';

function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/:id" element={<Home />} />
    <Route path="t" element={<TableChannel />} />
        <Route path="ch" element={<Channels />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;