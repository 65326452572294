import { useEffect, useState } from "react";
import fbDb from './firebase';
import {initializeApp} from 'firebase/app';
import { getDatabase, ref, child, get, push,onValue} from 'firebase/database';
import { Outlet, Link,BrowserRouter,Routes,Route } from "react-router-dom";
import React from "react";
import FormChannel from "./FormChannel";

const Channels = () => {
  const fbDatabase = fbDb;
  const [channelList,setChannelList]=useState([])
  useEffect(()=>{
    const dbRef = ref(fbDatabase);
    get(child(dbRef, 'Channels')).then((snapshot) => {
      if (snapshot.exists()) {
      console.log(snapshot.val())
      setChannelList(snapshot.val())
    }});
  },[])
  const editChannel=(channel)=>{
    alert(channel.url)
  }
    return (
        <ul>
          {
            channelList.map(ch=><li key={ch.name} className="channelrow"><button key={ch.name} type="button" onClick={()=>editChannel(ch)}>{ch.name}</button> &nbsp;
            <a target="_blank" style={{color:'#ccc'}} href={ch.url.startsWith('http')?ch.url:'https://youtube.com/watch?v='+ch.url}>{ch.url}</a> 
            {ch.blank==="1"?'Yeni Sekmede':''}<FormChannel channel={ch} /></li>)  
          }
        </ul>
      );
  };
export default Channels;
